<template>

    <v-card class="elevation-0 transparent">
        <v-card-title class="nolo-h3">{{ $t('welcomeBack') }}</v-card-title>
        <v-card-subtitle v-if="tagLine">{{ tagLine }}</v-card-subtitle>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-form v-model="isValid">
                        <v-text-field outlined v-model="email" type="email" required :label="$t('label.email')"
                                      autocomplete="username"
                                      background-color="white"
                                      :rules="[inputRules.requiredEmail, inputRules.email]"
                        />
                        <v-text-field outlined type="password" v-model="password" required :label="$t('label.password')"
                                      background-color="white" autocomplete="current-password"
                                      :rules="[inputRules.requiredPassword]"
                        />

                        <v-alert :value="errorMessage !== ''" color="error">{{ errorMessage }}</v-alert>

                        <v-checkbox class="mt-0" v-model="rememberMe" :label="$t('label.rememberMe')" :messages="$t('label.rememberMeDesc') "/>
                        <br />

                        <v-btn color="primary" @click="recaptcha" block depressed :disabled="loggingIn || !isValid"
                               :loading="loggingIn">
                            {{ $t('btn.login') }}
                        </v-btn>

                    </v-form>

                    <div class="text-center mt-6 nolo-p3">
                        <router-link :to="{name: 'forgot-password', params: { email: email} }">
                            {{ $t('link.forgotPassword') }}
                        </router-link>
                    </div>

                    <i18n tag="div" class="text-center black--text mt-6" path="offerRegistration">
                        <router-link :to="{name: 'register'}">{{ $t('link.register') }}</router-link>
                    </i18n>

                    <v-divider class="my-5"/>

                    <div class="nolo-subcopy text-center">
                        <i18n path="message.agreeByLoggingIn" tag="span">
                            <template v-slot:terms>
                                <a target="blank"
                                   :href="$t('url.terms', {domain: cmsDomain})">{{ $t('link.termsAndConditions') }}</a>
                            </template>
                            <template v-slot:cookie>
                                <a target="blank"
                                   :href="$t('url.cookie', {domain: cmsDomain})">{{ $t('link.cookiePolicy') }}</a>
                            </template>
                            <template v-slot:privacy>
                                <a target="blank"
                                   :href="$t('url.privacy', {domain: cmsDomain})">{{ $t('link.privacyPolicy') }}</a>
                            </template>
                        </i18n>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</template>

<script>
import APICustomer from '../../api/APICustomer';
import InputRules from "../../plugins/InputRules";

export default {
    name: "Login",
    data() {
        return {
            loggingIn: false,
            email: '',
            password: '',
            rememberMe: false,
            errorMessage: '',
            isValid: true,
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            recaptchaToken: '',
        }
    },
    props: {
        tagLine: {type: String, default: null}
    },
    mounted() {
        this.$recaptchaInstance.showBadge()
    },
    beforeDestroy() {
        this.$recaptchaInstance.hideBadge()
    },
    methods: {
        async recaptcha() {
            // show we are doing something while waiting for recaptcha action
            this.loggingIn = true;
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login')
            this.recaptchaToken = token;
            this.login();
        },
        login() {
            const me = this;
            me.loggingIn = true;
            me.errorMessage = '';
            APICustomer.login(this, this.email, this.password, this.rememberMe, this.recaptchaToken)
                .then(() => me.$emit('logged-in'))
                .catch(() => {
                    me.errorMessage = 'Invalid username or password'
                }).finally(() => {
                me.loggingIn = false;
            });
        }
    },
    computed: {
        inputRules: () => InputRules,
    }
}
</script>

<style scoped>

</style>
