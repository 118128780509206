<template>
    <v-form v-model="valid">
        <v-row dense>
            <v-col cols="12" :md="isAccount ? '6' : '12'" :class="isAccount ? 'pr-sm-2' : ''">
                <v-text-field outlined v-model="editPersonalDetails.firstName" @change="changed"
                              :rules="[inputRules.validName]"
                              background-color="white"
                              :label="$t('label.firstName')"/>
            </v-col>
            <v-col cols="12" :md="isAccount ? '6' : '12'" :class="isAccount ? 'pl-sm-2' : ''">
                <v-text-field outlined v-model="editPersonalDetails.lastName" @change="changed"
                              :rules="[inputRules.validName]"
                              background-color="white"
                              :label="$t('label.lastName')"/>
            </v-col>
            <v-col cols="12" :md="isAccount ? '6' : '12'" :class="isAccount ? 'pr-sm-2' : ''">
                <v-text-field outlined v-model="editPersonalDetails.email" type="email"
                              :rules="inputRulesEmail" @blur="checkEmail" :loading="emailError === null"
                              background-color="white"
                              :error-messages="emailErrorMessage" :error="emailError"
                              :label="$t('label.email')"/>
            </v-col>
            <v-col cols="12" :md="isAccount ? '6' : '12'" :class="isAccount ? 'pl-sm-2' : ''">
                <v-text-field outlined v-model="editPersonalDetails.phoneNumber" @change="changed"
                              :rules="phoneNumberRules" type="tel"
                              background-color="white"
                              :hint="$t('hint.phoneNumber')"
                              :label="$t('label.phoneNumber')"/>
            </v-col>
        </v-row>
    </v-form>

</template>

<script>

    import InputRules from "../../plugins/InputRules";
    import APICustomer from "../../api/APICustomer";

    export default {
        name: "PersonalDetailsEdit",
        data() {
            return {
                editPersonalDetails: {},
                valid: false,
                isEmailUnique: false,
                emailError: false,
                emailErrorMessage: '',
            }
        },
        mounted() {

        },
        methods: {
            changed() {
                this.$emit('input', this.editPersonalDetails);
            },
            checkEmail() {
                if (!this.uniqueEmail) return;

                const me = this;
                me.emailError = null;
                this.$emit('valid', false);

                APICustomer.emailInUse(this, this.editPersonalDetails.email)
                    .then(response => {
                        me.emailError = response.data.data.inUse;
                        me.emailErrorMessage = me.emailError ? this.$t('error.emailAlreadyInUse') : '';
                    })
                    .catch(() => {
                        me.emailError = false;
                        me.emailErrorMessage = this.$t('error.emailAlreadyInUse')
                    })
                    .finally(() => {
                            me.$emit('valid', me.valid && me.emailError === false);
                        }
                    );
            }
        },
        watch: {
            valid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('valid', newValue && this.emailError === false)
                }
            }
            ,
            value: {
                immediate: true,
                handler() {
                    this.editPersonalDetails = this.value;
                }
            }
        }
        ,
        props: {
            value: {
                type: Object
            }
            ,
            isAccount: {
                type: Boolean,
                default:
                    true
            }
            ,
            optionalPhoneNo: {
                type: Boolean,
                default:
                    false
            }
            ,
            uniqueEmail: {
                type: Boolean,
                default:
                    false
            }
        }
        ,
        computed: {
            inputRulesEmail() {
                return [InputRules.requiredEmail, InputRules.email];
            }
            ,
            phoneNumberRules() {
                if (this.optionalPhoneNo) {
                    return [this.inputRules.phoneNumber]
                } else {
                    return [this.inputRules.requiredPhoneNumber, this.inputRules.phoneNumber]
                }
            },
            inputRules: () => InputRules
        }
    }
</script>

<style scoped>

</style>
