<template>
    <v-row dense class="fill-height">
        <v-col cols="12" md="6" lg="8">

            <v-row dense v-if="!loggingIn">
                <v-col cols="12">
                    <p><a @click="goBack()" class="mb-8 secondary--text font-weight-bold"><v-icon class="mr-0">mdi-chevron-left</v-icon>{{ $t('btn.backToOrdering') }}</a></p>
                </v-col>
                <v-col cols="12" class="inbound-container">
                    <v-card class="elevation-0 transparent">
                        <v-card-title class="nolo-h3">{{ $t('guestCheckout') }}</v-card-title>
                        <v-container>
                            <v-row dense class="pb-6">
                                <i18n tag="v-col" cols="12" class="nolo-p2 nolo-dark-grey--text"
                                      path="message.enterDetailsOrLogin">
                                    <template v-slot:login>
                                        <a class="text-decoration-underline font-weight-bold"
                                           @click="offerLogin">{{ $t('link.login') }}</a>
                                    </template>
                                </i18n>
                                <v-col cols="12">
                                    <personal-details-edit v-model="guest" :isAccount="false" optional-phone-no
                                                           @valid="v => detailsValid = v"/>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox v-model="terms">
                                        <template v-slot:label>
                                            <i18n tag="span" path="label.acceptTerms" class="nolo-p3 nolo-dark-grey--text">
                                                <a :href="$t('url.terms', {domain: cmsDomain})" class="font-weight-bold"
                                                   @click.stop target="_blank">{{ $t('link.termsAndConditions') }}</a>
                                            </i18n>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox class="nolo-p3 mt-0" v-model="marketing">
                                        <template v-slot:label>
                                            <span class="nolo-dark-grey--text nolo-p3">{{ $t('label.marketing') }}</span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn block :disabled="!valid" @click="guestContinue" color="primary">
                                        {{ $t('btn.continue') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="pt-5">
                                    <i18n tag="div" path="message.marketingOptIn" class="nolo-subcopy px-1">
                                        <a target="_blank" :href="$t('url.privacy', {domain: cmsDomain})" class="font-weight-bold">{{ $t('link.privacyPolicy') }}</a>
                                    </i18n>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>

            <v-row dense v-else>
                <v-col cols="12">
                    <p><a @click="offerGuest()" class="mb-6 secondary--text font-weight-bold"><v-icon class="mr-0">mdi-chevron-left</v-icon>{{ $t('btn.backToCheckout') }}</a></p>
                </v-col>
                <v-col cols="12" class="inbound-container">
                    <login @logged-in="justLoggedIn"/>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="12" md="6" lg="4" class="hidden-sm-and-down pt-4">
            <shop-basket-summary :basket="basket" :upsells="upsells" readonly :language="appLanguage"
                                 :currency="appCurrency"/>
        </v-col>
    </v-row>

</template>

<script>
    import PersonalDetailsEdit from "../Account/PersonalDetailsEdit";
    import CheckoutHelper from "../../mixins/CheckoutHelper";
    import Login from "../Account/Login";
    import ShopBasketSummary from "../Shop/ShopBasketSummary";
    import Gtag from "../../mixins/Gtag";
    import Gtm from "../../mixins/Gtm";

    export default {
        name: "Guest",
        components: {Login, PersonalDetailsEdit, ShopBasketSummary},
        data() {
            return {
                guest: {},
                marketing: false,
                terms: false,
                loggingIn: false,
                detailsValid: false,
            }
        },
        mixins: [
            CheckoutHelper,
            Gtag,
            Gtm
        ],
        mounted() {
            this.gtmViewCartEvent(this.basket);

            if (this.isLoggedIn) {
                this.checkoutNextRoute(true);
            }
            if (this.justRegistered) {
                this.justRegistered = false;
                this.loggingIn = true;
            }
            this.guest = JSON.parse(JSON.stringify(this.$store.getters['session/guest']));
            // this.marketing = this.$store.getters['session/guestMarketing'];

            if (this.paymentFail) {
                this.appShowErrorDialog(this.$i18n.t('error.paymentRejected'), 'error', this.disableRejectionPopup)
            }
        },
        methods: {
            goBack() {
                this.$router.push({name: 'order'});
            },
            justLoggedIn() {
                this.checkoutNextRoute(true);
            },
            offerLogin() {
                this.loggingIn = true;
            },
            offerGuest() {
                this.loggingIn = false;
            },
            guestContinue() {
                this.$store.commit('session/guest', this.guest);
                this.$store.commit('session/guestMarketing', this.marketing);
                this.checkoutNextRoute(false);
            },
            disableRejectionPopup() {
                this.paymentFail = false;
            }
        },
        computed: {
            paymentFail: {
                get() { return this.$store.getters['session/isPaymentRejected']; },
                set(newValue) {
                    this.$store.commit('session/isPaymentRejected', newValue)
                }
            },
            justRegistered: {
                get() { return this.$store.getters['app/justRegistered']; },
                set(newValue) {
                    this.$store.commit('app/justRegistered', newValue);
                }
            },
            valid() {
                return this.detailsValid && this.terms;
            },
            basket() {
                return this.$store.getters['session/basket'];
            },
            upsells() {
                return this.$store.getters['session/upsells'];
            }
        }
    }
</script>

<style scoped>

</style>
