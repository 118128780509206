<script>

import Constants from "../api/Constants";

    export default {

        methods: {
            checkoutNextRoute(replace) {
                let route = null;
                switch (this.$route.name) {
                    case 'checkout':

                        if (this.sessOrderType === Constants.OrderType.Curbside) {
                            route = 'checkout-curbside';
                            break;
                        } else {
                            route = 'checkout-contact-store';
                            break;
                        }
                    case 'checkout-curbside':
                        route = 'checkout-contact-store';
                        break;
                    default:
                        throw 'No next route for ' + this.$route.name
                }
                if (replace) {
                    this.$router.replace({name: route});
                } else {
                    this.$router.push({name: route});
                }
            }
        },
        computed: {
            sessOrderType() {
                return this.$store.getters['session/orderType'];
            },
        }

    }
</script>
